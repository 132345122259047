import Vue from "vue";

Vue.mixin({
    methods: {
        $_adminMixin_route_back: async function (previousRoute, alternateRoute) {
            if(previousRoute && previousRoute.name) {
                await this.$router.back();
            } else {
                await this.$router.replace(alternateRoute)
            }
        },
        $_adminMixin_convert_date_time: function (dateTime) {
            return this.$_generalMixin_convert_date_time_format({
                    dataDateTime: dateTime,
                    formatDateTime: "DD MMMM yyyy, HH:mm"
                }
            )
        },
        $_adminMixin_convert_date: function (dateTime) {
            return this.$_generalMixin_convert_date_time_format({
                    dataDateTime: dateTime,
                    formatDateTime: "DD MMMM yyyy"
                }
            )
        },
        $_adminMixin_setup_pagination_url: async function (routeName, queryObject) {
            await this.$router.replace({
                name: routeName,
                query: {...queryObject}
            }).catch(() => {
            });
        }
    }
});