import {lazyLoad, lazyLoadComponent} from '@/router/coreRouting'

const defaultPerpustakaanPath = "perpustakaan/"
export default [
  {
    path: "/perpustakaan",
    redirect: {name: "Perpustakaan Beranda"},
    name: "Perpustakaan Home",
    component: lazyLoadComponent("PerpustakaanLayout", defaultPerpustakaanPath),
    children: [
      {
        path: "beranda",
        name: "Perpustakaan Beranda",
        component: lazyLoad("PerpustakaanBeranda", defaultPerpustakaanPath)
      },
      {
        path: "berita",
        name: "Perpustakaan List Berita",
        component: lazyLoad("PerpustakaanListBerita", defaultPerpustakaanPath)
      },
      {
        path: "berita-detail/:slug",
        name: "Perpustakaan Detail Berita",
        component: lazyLoad("PerpustakaanDetailBerita", defaultPerpustakaanPath)
      },
      {
        path: "tentang",
        name: "Perpustakaan Tentang",
        component: lazyLoad("PerpustakaanTentang", defaultPerpustakaanPath)
      },
      {
        path: "tata-tertib",
        name: "Perpustakaan Tata Tertib",
        component: lazyLoad("PerpustakaanTataTertib", defaultPerpustakaanPath)
      },
      {
        path: "prosedur",
        name: "Perpustakaan Prosedur",
        component: lazyLoad("PerpustakaanProsedur", defaultPerpustakaanPath)
      },
      {
        path: "faq",
        name: "Perpustakaan FAQ",
        component: lazyLoad("PerpustakaanFAQ", defaultPerpustakaanPath)
      },
      {
        path: "personil",
        name: "Perpustakaan Personil",
        component: lazyLoad("PerpustakaanPersonil", defaultPerpustakaanPath)
      },
    ]
  },
]