const mainMenu = [
  {
    routeName: "Beranda",
    classMainMenu: "link-beranda"
  }
];

const subMenu = [
  // {
  //   routeName: "Sejarah",
  //   classMainMenu: "link-profil",
  //   classSubMenu: "link-sejarah"
  // },
  {
    routeName: "Sikap Budaya",
    classMainMenu: "link-profil",
    classSubMenu: "link-visi-misi-tujuan"
  },
  {
    routeName: "Pidato Ketua",
    classMainMenu: "link-profil",
    classSubMenu: "link-pidato"
  },
  {
    routeName: "Kerjasama",
    classMainMenu: "link-profil",
    classSubMenu: "link-kerjasama"
  },
  {
    routeName: "Perpustakaan Beranda",
    classMainMenu: "link-unit",
    classSubMenu: "link-perpustakaan"
  },
  {
    routeName: "Beranda BSITD",
    classMainMenu: "link-unit",
    classSubMenu: "link-bsitd"
  },
  {
    routeName: "LPPM Beranda",
    classMainMenu: "link-unit",
    classSubMenu: "link-lppm"
  },
  {
    routeName: "Sarana Prasarana",
    classMainMenu: "link-mahasiswa",
    classSubMenu: "link-sarana"
  },
  {
    routeName: "Prestasi",
    classMainMenu: "link-mahasiswa",
    classSubMenu: "link-prestasi"
  },

  {
    routeName: "Lowongan Kerja",
    classMainMenu: "link-lowongan-kerja"
  },
  {
    routeName: "Contact Us",
    classMainMenu: "menu-contact-us"
  },
  {
    routeName: "Fakultas Informatika",
    classMainMenu: "link-fakultas",
    classSubMenu: "link-fakultas-informatika"
  },
  {
    routeName: "Fakultas Bisnis",
    classMainMenu: "link-fakultas",
    classSubMenu: "link-fakultas-bisnis"
  },
];

// pengelompokan menu
// dipakai untuk generate menu
const allMenuProfil = [
  // {
  //   className: "link-sejarah",
  //   onClickRouteName: "Sejarah",
  //   label: "Sejarah"
  // },

  {
    className: "link-visi-misi-tujuan",
    onClickRouteName: "Sikap Budaya",
    label: "Visi, Misi, dan Tujuan"
  },
  {
    className: "link-pidato",
    onClickRouteName: "Pidato Ketua",
    label: "Pidato Rektor"
  },
  {
    className: "link-kerjasama",
    onClickRouteName: "Kerjasama",
    label: "Kerjasama"
  }
];

const allMenuFakultas = [
  {
    className: "link-fakultas-informatika",
    onClickRouteName: "Fakultas Informatika",
    label: "Fakultas Informatika"
  },
  {
    className: "link-fakultas-bisnis",
    onClickRouteName: "Fakultas Bisnis",
    label: "Fakultas Bisnis"
  },
];

const menuHimpunanMahasiswaFakultas = {
  classMainMenu: "link-mahasiswa",
  classSubMenu: "link-himpunan-mahasiswa",
  classChildSubMenu: "link-",
  routeName: "Komunitas Mahasiswa",
  className: "child-sub-menu link-",
  namaSingkat: "",
  label: "",
};

const menuUnitKegiatanMahasiswa = {
  classMainMenu: "link-mahasiswa",
  classSubMenu: "link-unit-kegiatan-mahasiswa",
  classChildSubMenu: "link-",
  routeName: "Unit Kegiatan Mahasiswa",
  className: "child-sub-menu link-",
  namaSingkat: "",
  label: "",
};

export default {
  mainMenu,
  subMenu,
  menuHimpunanMahasiswaFakultas,
  menuUnitKegiatanMahasiswa,
  allMenuProfil,
  allMenuFakultas
};
