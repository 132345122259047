import axios from "@/axios";

async function login(formData) {
  const endpoint = "ldap-login";
  return await axios.post(endpoint, formData);
}

async function getMe() {
  const endpoint = "ldap-me";
  return await axios.get(endpoint);
}

async function logout() {
  const endpoint = "ldap-logout";
  return await axios.post(endpoint);
}

export default {
  login,
  getMe,
  logout
};
