const listMenuPerpustakaan = [
  {
    id: 1,
    name: "Beranda",
    className: "link-perpustakaan-beranda",
    routeName: "Perpustakaan Beranda"
  },
  {
    id: 2,
    name: "Personil",
    className: "link-perpustakaan-personil",
    routeName: "Perpustakaan Personil"
  },
  {
    id: 3,
    name: "Tentang Perpustakaan",
    className: "link-perpustakaan-tentang",
    routeName: "Perpustakaan Tentang"
  }
];

const listAllMenuPerpustakaan = [
  {
    id: 1,
    name: "Beranda",
    className: "link-perpustakaan-beranda",
    routeName: "Perpustakaan Beranda"
  },
  {
    id: 2,
    name: "Tentang Perpustakaan",
    className: "link-perpustakaan-tentang",
    routeName: "Perpustakaan Tentang"
  },
  {
    id: 3,
    name: "Tata Tertib Perpustakaan",
    className: "link-perpustakaan-tentang",
    routeName: "Perpustakaan Tata Tertib"
  },
  {
    id: 4,
    name: "Prosedur Perpustakaan",
    className: "link-perpustakaan-tentang",
    routeName: "Perpustakaan Prosedur"
  },
  {
    id: 5,
    name: "FAQ",
    className: "link-perpustakaan-tentang",
    routeName: "Perpustakaan FAQ"
  },
  {
    id: 6,
    name: "List Berita Perpustakaan",
    className: "link-perpustakaan-beranda",
    routeName: "Perpustakaan List Berita"
  },
  {
    id: 7,
    name: "Detail Berita Perpustakaan",
    className: "link-perpustakaan-beranda",
    routeName: "Perpustakaan Detail Berita"
  },
  {
    id: 9,
    name: "Perpustakaan Personil",
    className: "link-perpustakaan-personil",
    routeName: "Perpustakaan Personil"
  },
];

export default {
  listMenuPerpustakaan,
  listAllMenuPerpustakaan
};
