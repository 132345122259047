import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from 'vuelidate'
import infiniteScroll from "vue-infinite-scroll";
import CKEditor from '@ckeditor/ckeditor5-vue2';

// Bootstrap
import {
  BootstrapVue,
  IconsPlugin
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'

import VuePageTransition from 'vue-page-transition'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import AOS from "aos";
import "aos/dist/aos.css";

// mixins for global function
import '@/mixins/generalMixin.js'
import '@/mixins/adminMixin.js'

Vue.config.productionTip = false;

Vue.use(infiniteScroll)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VuePageTransition)
Vue.use(CKEditor)

Vue.component('v-select', vSelect)

AOS.refresh()
AOS.init()

AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 30, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'linear', // default easing for AOS animations
  once: true, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");