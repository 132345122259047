import {lazyLoad} from "@/router/coreRouting";

export default [
  {
    path: "/403",
    name: "Forbidden",
    component: lazyLoad("ForbiddenPage")
  },
  {
    path: "/404",
    name: "Not Found",
    alias: "*",
    component: lazyLoad("NotFound")
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: lazyLoad("MaintenancePage")
  },
]