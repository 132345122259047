function lazyLoadComponent(view, path = "") {
  return () => import(`@/components/${path}${view}.vue`);
}

function lazyLoad(view, path = "") {
  return () => import(`@/views/${path}${view}.vue`);
}

const coreRouting = {
  lazyLoad,
  lazyLoadComponent
}

export {
  lazyLoad,
  lazyLoadComponent
};

export default coreRouting
