import {lazyLoad} from '@/router/coreRouting'

const defaultProfilPath = "profil/"
export default [
  // {
  //   path: "sejarah",
  //   name: "Sejarah",
  //   component: lazyLoad("ProfilSejarah", defaultProfilPath)
  // },
  {
    path: "sikap-budaya",
    name: "Sikap Budaya",
    component: lazyLoad("ProfilSikapBudaya", defaultProfilPath)
  },
  {
    path: "kerjasama",
    name: "Kerjasama",
    component: lazyLoad("ProfilKerjasama", defaultProfilPath)
  },
  {
    path: "pidato-wisuda",
    name: "Pidato Ketua",
    component: lazyLoad("ProfilPidatoKetua", defaultProfilPath)
  },
]
