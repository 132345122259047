const _classMainMenu = "link-bsitd-tentang";

const listMenuBSITD = [
  {
    id: 1,
    name: "Beranda",
    classMainMenu: "link-bsitd-beranda",
    routeName: "Beranda BSITD"
  },
  {
    id: 2,
    name: "Tentang",
    classMainMenu: "link-bsitd-tentang"
  }
];

const listSubMenuBSITD = [
  {
    id: 3,
    name: "Tugas, Sejarah, Visi, Misi, Tujuan",
    classMainMenu: _classMainMenu,
    classSubMenu: "link-bsitd-tugas-sejarah-visi-misi-tujuan",
    routeName: "Tugas Sejarah Visi Misi Tujuan BSITD"
  },
  {
    id: 4,
    name: "Struktur Organisasi",
    classMainMenu: _classMainMenu,
    classSubMenu: "link-bsitd-struktur-organisasi",
    routeName: "Struktur Organisasi BSITD"
  },
  {
    id: 5,
    name: "BSITD Personil",
    classMainMenu: _classMainMenu,
    classSubMenu: "link-bsitd-personil",
    routeName: "BSITD Personil"
  }
];

const listAllMenuBSITD = [...listMenuBSITD, ...listSubMenuBSITD];

export default {
  listMenuBSITD,
  listSubMenuBSITD,
  listAllMenuBSITD
};
