import {lazyLoad, lazyLoadComponent} from '@/router/coreRouting'

const defaultLppmPath = "lppm/"
export default [
  {
    path: "/lppm",
    redirect: {name: "LPPM Beranda"},
    name: "Home LPPM",
    component: lazyLoadComponent("LPPMLayout", defaultLppmPath),
    children: [
      {
        path: "beranda",
        name: "LPPM Beranda",
        component: lazyLoad("LPPMBeranda", defaultLppmPath)
      },
      {
        path: "news",
        name: "LPPM List News",
        component: lazyLoad("LPPMListNews", defaultLppmPath)
      },
      {
        path: "news/:slug",
        name: "LPPM Detail News",
        component: lazyLoad("LPPMDetailNews", defaultLppmPath)
      },
      {
        path: "agenda",
        name: "LPPM List Agenda",
        component: lazyLoad("LPPMListAgenda", defaultLppmPath)
      },
      {
        path: "agenda/:slug",
        name: "LPPM Detail Agenda",
        component: lazyLoad("LPPMDetailAgenda", defaultLppmPath)
      },
      {
        path: "penelitian/:id",
        name: "LPPM Detail Penelitian",
        component: lazyLoad("LPPMDetailPenelitianPengabdian", defaultLppmPath)
      },
      {
        path: "pengabdian/:id",
        name: "LPPM Detail Pengabdian",
        component: lazyLoad("LPPMDetailPenelitianPengabdian", defaultLppmPath)
      },
      {
        path: "penelitian",
        name: "LPPM Penelitian",
        component: lazyLoad("LPPMPenelitian", defaultLppmPath)
      },
      {
        path: "pengabdian",
        name: "LPPM Pengabdian",
        component: lazyLoad("LPPMPengabdian", defaultLppmPath)
      },
      {
        path: "publikasi",
        name: "LPPM Publikasi",
        component: lazyLoad("LPPMPublikasi", defaultLppmPath)
      },
      {
        path: "download",
        name: "LPPM Download",
        component: lazyLoad("LPPMDownload", defaultLppmPath)
      },
      {
        path: "personil",
        name: "LPPM Personil",
        component: lazyLoad("LPPMPersonil", defaultLppmPath)
      }
    ]
  },
];