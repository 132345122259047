import AuthenticateApi from "@/api/AuthenticateApi"

const initialState = () => ({
  token: '',
  user: {},
  roles: [],
})

async function doLogin(commit, loginUser) {
  const responseLogin = await AuthenticateApi.login(loginUser)
  const dataLogin = responseLogin.data.data
  const token = dataLogin.token
  commit("SET_TOKEN", token)
  return responseLogin;
}

async function doGetMe(commit) {
  const responseMe = await AuthenticateApi.getMe()
  const dataMe = responseMe.data.data

  commit("SET_USER", dataMe.individu)
  commit("SET_ROLES", dataMe.roles)
  return responseMe
}

const state = initialState()

const getters = {
  getIsLoggedIn: (state) => !!state.token,
  getToken: (state) => state.token,
  getUser: (state) => state.user,
  getRoles: (state) => state.roles,
}

const actions = {
  async login({ commit }, loginUser) {
    await doLogin(commit, loginUser)
    return await doGetMe(commit)
  },
  async me({ commit }) {
    return await doGetMe(commit)
  },
  async logout({commit}) {
    try {
      await AuthenticateApi.logout()
    } catch (err) {
      // do nothing
    }
    commit('RESET_STATE')
    localStorage.clear();
  }
}

const mutations = {
  RESET_STATE(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },

  SET_USER(state, user) {
    state.user = user
  },

  SET_ROLES(state, roles) {
    state.roles = roles
  },

  SET_TOKEN(state, token) {
    state.token = token;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
