const listMenuLPPM = [
  {
    id: 1,
    name: "Beranda",
    className: "link-lppm-beranda",
    routeName: "LPPM Beranda"
  },
  {
    id: 2,
    name: "Download",
    className: "link-lppm-download",
    routeName: "LPPM Download"
  },
  {
    id: 3,
    name: "Personil",
    className: "link-lppm-personil",
    routeName: "LPPM Personil"
  },
];
const listSubMenu = [
  {
    name: "Penelitian",
    className: "link-lppm-beranda",
    routeName: "LPPM Penelitian"
  },
  {
    name: "Pengabdian",
    className: "link-lppm-beranda",
    routeName: "LPPM Pengabdian"
  },
  {
    name: "Publikasi",
    className: "link-lppm-beranda",
    routeName: "LPPM Publikasi"
  }
];
export default {
  listMenuLPPM,
  listSubMenu
};
