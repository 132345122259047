import Vue from "vue";

import router from "@/router/index.js";

import MainMenuNavigation from "@/components/menu/MainMenuNavigation.js";
import LPPMMenu from "@/components/lppm/LPPMMenu.js";
import MenuPerpustakaan from "@/components/perpustakaan/PerpustakaanMenu.js";
import MenuBSITD from "@/components/bsitd/MenuBSITD.js";

const moment = require("moment");

Vue.mixin({
  methods: {
    $_generalMixin_remove_selected_menu: function () {
      let lastElementSelectedMenu = document.getElementsByClassName(
          "selected-menu"
      );
      while (lastElementSelectedMenu.length > 0) {
        lastElementSelectedMenu[0].classList.remove("selected-menu");
      }
      let lastElementSelectedMainMenu = document.getElementsByClassName(
          "selected-main-menu"
      );
      while (lastElementSelectedMainMenu.length > 0) {
        lastElementSelectedMainMenu[0].classList.remove("selected-main-menu");
      }
      let lastElementSelectedSubMenu = document.getElementsByClassName(
          "selected-sub-menu"
      );
      while (lastElementSelectedSubMenu.length > 0) {
        lastElementSelectedSubMenu[0].classList.remove("selected-sub-menu");
      }
      let lastElementSelectedChildSubMenu = document.getElementsByClassName(
          "selected-child-sub-menu"
      );
      while (lastElementSelectedChildSubMenu.length > 0) {
        lastElementSelectedChildSubMenu[0].classList.remove("selected-child-sub-menu");
      }
    },
    $_generalMixin_find_route: function (routeName) {
      // cek apakah routeName tersebut ada pada router/index.js ?
      let allVueRouter = [];
      // ini untuk get routeName dari child
      router.options.routes.forEach(r => {
        if (r.children && r.children.length > 0) {
          allVueRouter.push(...r.children);
        } else {
          allVueRouter.push(r);
        }
      });
      let routeFound = allVueRouter.find(menu => menu.name === routeName);
      if (routeFound && routeFound.name) {
        // jika ada maka route ke routeName
        return {name: routeName};
      } else {
        // jika tidak ada maka redirect ke not found
        return {name: "Not Found"};
      }
    },
    $_generalMixin_menu_route_to_fakultas: function (routeName, slugPath, classMenu, classSubMenu) {
      this.$_generalMixin_remove_selected_menu();
      let elementSelectedMenu = document.getElementsByClassName(
          classMenu
      );
      for (let index = 0; index < elementSelectedMenu.length; index++) {
        elementSelectedMenu[index].classList.add("selected-main-menu");
      }
      let elementSelectedSubMenu = document.getElementsByClassName(
          classSubMenu
      );
      for (let index = 0; index < elementSelectedSubMenu.length; index++) {
        elementSelectedSubMenu[index].classList.add("selected-sub-menu");
      }
    },
    $_generalMixin_menu_route_to_himpunan_mahasiswa: function (routeName, classMenu, classSubMenu) {
      let nextRouteFound = this.$_generalMixin_find_route(routeName);
      this.$_generalMixin_remove_selected_menu();
      let elementSelectedMenu = document.getElementsByClassName(
          classMenu
      );
      for (let index = 0; index < elementSelectedMenu.length; index++) {
        elementSelectedMenu[index].classList.add("selected-main-menu");
      }
      let elementSelectedSubMenu = document.getElementsByClassName(
          classSubMenu
      );
      for (let index = 0; index < elementSelectedSubMenu.length; index++) {
        elementSelectedSubMenu[index].classList.add("selected-sub-menu");
      }
      return nextRouteFound;
    },
    $_generalMixin_menu_route_to: function (routeName) {
      // next Route Found => route yg ditemukan baik merupakan main menu Homesite / page LPPM dll.
      let nextRouteFound = this.$_generalMixin_find_route(routeName);
      // cek routeName ada pada MainMenuNavigation.js?
      let selectedMenu = MainMenuNavigation.mainMenu.find(
        menu => menu.routeName === routeName
      );
      if (!(selectedMenu && selectedMenu.routeName)) {
        selectedMenu = MainMenuNavigation.subMenu.find(
          menu => menu.routeName === routeName
        );
        if (!(selectedMenu && selectedMenu.routeName)) {
          // jika tidak ada maka redirect ke not found
          return {name: "Not Found"};
        }
      }
      // jika ada hapus selected menu
      this.$_generalMixin_remove_selected_menu();
      // update selected menu berdasarkan MainMenuNavigation
      if (selectedMenu.classMainMenu) {
        let elementSelectedMainMenu = document.getElementsByClassName(
            selectedMenu.classMainMenu
        );
        for (let index = 0; index < elementSelectedMainMenu.length; index++) {
          elementSelectedMainMenu[index].classList.add("selected-main-menu");
        }
      }
      if (selectedMenu.classSubMenu) {
        let elementSelectedSubMenu = document.getElementsByClassName(
            selectedMenu.classSubMenu
        );
        for (let index = 0; index < elementSelectedSubMenu.length; index++) {
          elementSelectedSubMenu[index].classList.add("selected-sub-menu");
        }
      }
      if (selectedMenu.classChildSubMenu) {
        let elementSelectedChildSubMenu = document.getElementsByClassName(
            selectedMenu.classChildSubMenu
        );
        for (
            let index = 0;
            index < elementSelectedChildSubMenu.length;
            index++
        ) {
          elementSelectedChildSubMenu[index].classList.add(
              "selected-child-sub-menu"
          );
        }
      }
      return nextRouteFound;
    },
    $_generalMixin_menu_lppm_route_to: function (routeName) {
      // next Route Found => route yg ditemukan baik merupakan main menu Homesite / page LPPM dll.
      let nextRouteFound = this.$_generalMixin_find_route(routeName);
      let selectedMenu = LPPMMenu.listMenuLPPM.find(
        menu => menu.routeName === routeName
      );
      if (!(selectedMenu && selectedMenu.routeName)) {
        selectedMenu = LPPMMenu.listSubMenu.find(
          menu => menu.routeName === routeName
        );
      }
      if (!(selectedMenu && selectedMenu.routeName)) {
        // jika tidak ada maka redirect ke not found
        return {name: "Not Found"};
      }
      this.$_generalMixin_remove_selected_menu();
      // update selected menu berdasarkan MainMenuNavigation
      if (selectedMenu?.className) {
        let elementSelectedMenu = document.getElementsByClassName(
            selectedMenu.className
        );
        for (let index = 0; index < elementSelectedMenu.length; index++) {
          elementSelectedMenu[index].classList.add("selected-menu");
        }
      }
      return nextRouteFound;
    },
    $_generalMixin_menu_perpustakaan_route_to: function (routeName) {
      // next Route Found => route yg ditemukan baik merupakan main menu Homesite / page LPPM / Perpustakaan dll.
      let nextRouteFound = this.$_generalMixin_find_route(routeName);
      let selectedMenu = MenuPerpustakaan.listAllMenuPerpustakaan.find(
        menu => menu.routeName === routeName
      );
      this.$_generalMixin_remove_selected_menu();
      if (selectedMenu?.className) {
        let elementSelectedMenu = document.getElementsByClassName(
            selectedMenu.className
        );
        for (let index = 0; index < elementSelectedMenu.length; index++) {
          elementSelectedMenu[index].classList.add("selected-menu");
        }
      }
      return nextRouteFound;
    },
    $_generalMixin_menu_bsitd_route_to: function (routeName) {
      // next Route Found => route yg ditemukan baik merupakan main menu Homesite / page LPPM / Perpustakaan dll.
      let nextRouteFound = this.$_generalMixin_find_route(routeName);
      let selectedMenu = MenuBSITD.listAllMenuBSITD.find(
        menu => menu.routeName === routeName
      );

      if (!(selectedMenu && selectedMenu.routeName)) {
        selectedMenu = MainMenuNavigation.subMenu.find(
          menu => menu.routeName === routeName
        );
      }
      this.$_generalMixin_remove_selected_menu();
      // update main menu selected
      if (selectedMenu?.classMainMenu) {
        let elementSelectedMenu = document.getElementsByClassName(
            selectedMenu.classMainMenu
        );
        for (let index = 0; index < elementSelectedMenu.length; index++) {
          elementSelectedMenu[index].classList.add("selected-menu");
        }
      }
      if (selectedMenu?.classSubMenu) {
        let elementSelectedMenu = document.getElementsByClassName(
            selectedMenu.classSubMenu
        );
        for (let index = 0; index < elementSelectedMenu.length; index++) {
          elementSelectedMenu[index].classList.add("selected-menu");
        }
      }
      return nextRouteFound;
    },
    $_generalMixin_date_time_from_now: function ({
                                                   dataDateTime,
                                                   invalidValue = "-"
                                                 }) {
      let tempMoment = {...moment};
      tempMoment.updateLocale("id", {
        relativeTime: {
          future: "%s lagi",
          past: "%s yang lalu",
          s: "1 detik",
          ss: "%d detik",
          m: "1 menit",
          mm: "%d beberapa menit",
          h: "1 jam",
          hh: "%d jam",
          d: "1 hari",
          dd: "%d hari",
          w: "1 minggu",
          ww: "%d minggu",
          M: "1 bulan",
          MM: "%d bulan",
          y: "1 tahun",
          yy: "%d tahun"
        }
      });
      if (!dataDateTime) {
        return invalidValue;
      }
      const res = moment(dataDateTime).fromNow();
      return res;
    },
    $_generalMixin_convert_date_time_format: function ({
                                                         dataDateTime,
                                                         formatDateTime = "dddd, DD MMMM YYYY",
                                                         localeDateTime = "id",
                                                         invalidValue = "-"
                                                       }) {
      if (!dataDateTime) {
        return invalidValue;
      }
      const res = moment(dataDateTime).locale(localeDateTime).format(formatDateTime);
      return res;
    },
    $_generalMixin_error_image: function (event, placeholderImage) {
      const defaultPlaceholder = require("@/assets/images/no-picture.png");
      const imageElement = event.target;
      imageElement.src = placeholderImage || defaultPlaceholder;
      imageElement.onError = null;
    },
    $_generalMixin_make_toast: function ({
                                           title = "",
                                           message = "",
                                           toaster = "b-toaster-bottom-right",
                                           variant = "default",
                                           autoHideDelay = 5000,
                                           append = false
                                         }) {
      this.$root.$bvToast.toast(message, {
        title,
        toaster,
        variant,
        autoHideDelay,
        appendToast: append
      });
    },
    $_generalMixin_sleep: function (m) {
      return new Promise(r => setTimeout(r, m));
    },
  }
});
