<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
};
</script>
<style lang="scss">
p,
li {
  line-height: 1.8;
}
@import "~@/assets/scss/Global.scss";
</style>

<style lang="scss" scoped>
@import "~@/assets/scss/App.scss";
</style>
