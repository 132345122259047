const state = {
  showSidebar: true,
  expandSidebar: true
};

const getters = {
  getShowSidebar: function(state) {
    return state.showSidebar;
  },
  getExpandSidebar: function(state) {
    return state.expandSidebar;
  },
};

const mutations = {
  SET_SHOW_SIDEBAR(state, data) {
    state.showSidebar = data;
  },
  SET_EXPAND_SIDEBAR(state, data) {
    state.expandSidebar = data;
  },
};

const actions = {
  toggleExpandSidebar({state, commit}) {
    commit('SET_EXPAND_SIDEBAR', !state.expandSidebar)
  },
  updateShowSidebar({state, commit}, data) {
    commit('SET_SHOW_SIDEBAR', data)
    if(data) {
      commit('SET_EXPAND_SIDEBAR', data)
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}