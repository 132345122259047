import {lazyLoad, lazyLoadComponent} from '@/router/coreRouting'
import profil from "@/router/routes/profil";

const defaultProgramStudiPath = "program-studi/"
const defaultMahasiswaPath = "mahasiswa/"

export default [
  {
    path: "/",
    redirect: {name: "Lowongan Kerja"},
    name: "Home",
    component: lazyLoadComponent("TheMainLayout"),
    children: [
      // {
      //   path: "",
      //   name: "Beranda",
      //   component: lazyLoad("BerandaPage")
      // },
      // {
      //   path: "berita/:slug",
      //   name: "Berita Detail",
      //   component: lazyLoad("BeritaAgendaDetail")
      // },
      // {
      //   path: "berita",
      //   name: "List Berita",
      //   component: lazyLoad("ListBerita")
      // },
      // {
      //   path: "agenda",
      //   name: "List Agenda",
      //   component: lazyLoad("ListAgenda")
      // },
      // {
      //   path: "agenda/:slug",
      //   name: "Agenda Detail",
      //   component: lazyLoad("BeritaAgendaDetail")
      // },
      // {
      //   path: "testimoni",
      //   name: "Testimoni",
      //   component: lazyLoad("TestimoniDetail")
      // },
      // ...profil,
      // {
      //   path: "fakultas-bisnis",
      //   name: "Fakultas Bisnis",
      //   component: lazyLoad("FakultasPage", defaultProgramStudiPath),
      // },
      // {
      //   path: "fakultas-bisnis/:namaProgramStudi",
      //   name: "Program Studi Bisnis",
      //   component: lazyLoad("ProgramStudiPage", defaultProgramStudiPath),
      // },
      // {
      //   path: "fakultas-informatika",
      //   name: "Fakultas Informatika",
      //   component: lazyLoad("FakultasPage", defaultProgramStudiPath),
      // },
      // {
      //   path: "fakultas-informatika/:namaProgramStudi",
      //   name: "Program Studi Informatika",
      //   component: lazyLoad("ProgramStudiPage", defaultProgramStudiPath),
      // },
      // {
      //   path: "lecturer/:namaProgramStudi",
      //   name: "Our Lecturer",
      //   component: lazyLoad("LecturerPage", defaultProgramStudiPath)
      // },
      // {
      //   path: "komunitas-mahasiswa/:namaSingkatKomunitas",
      //   name: "Komunitas Mahasiswa",
      //   component: lazyLoadComponent("KomunitasMahasiswaLayout", defaultMahasiswaPath)
      // },
      // {
      //   path: "sarana-prasarana",
      //   name: "Sarana Prasarana",
      //   component: lazyLoad("MahasiswaSaranaPrasarana", defaultMahasiswaPath)
      // },
      // {
      //   path: "prestasi",
      //   name: "Prestasi",
      //   component: lazyLoad("MahasiswaPrestasi", defaultMahasiswaPath)
      // },
      {
        // path: "lowongan-kerja",
        path: "",
        name: "Lowongan Kerja",
        component: lazyLoad("LowonganKerja")
      },
      // {
      //   path: "kontak",
      //   name: "Contact Us",
      //   component: lazyLoad("ContactUsPage")
      // }
    ]
  },

]