import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store"

import beranda from "@/router/routes/beranda";
import perpustakaan from "@/router/routes/perpustakaan";
// import bsitd from "@/roguter/routes/bsitd";
import lppm from "@/router/routes/lppm";
// import alumni from "@/router/routes/alumni";
import admin from "@/router/routes/admin";
import errorRoute from "@/router/routes/errorRoute";

Vue.use(VueRouter);

const routes = [
  ...beranda,
  // ...perpustakaan,
  // ...bsitd,
  // ...lppm,
  // ...alumni,
  ...admin,
  ...errorRoute
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {x: 0, y: 0};
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const isRouteRequiredAuth = to.meta.requiresAuth;
  const isUserLoggedIn = store.getters['authStore/getIsLoggedIn']

  if (isRouteRequiredAuth && !isUserLoggedIn) {
    next({name: "Not Found"});
    return
  } else if (isRouteRequiredAuth && isUserLoggedIn) {
    try {
      await store.dispatch("authStore/me");
    } catch (err) {
      if (err.status === 401) {
        await store.dispatch("authStore/logout");
        next({name: "Admin Login", params: {isSessionEnd: true}})
        return
      }
    }

    const userRoles = store.getters['authStore/getRoles'];
    const isUserHasAccess = userRoles.some((role) => role === to.meta.role);
    if (!isUserHasAccess) {
      next({name: "Forbidden"})
      return
    }
  }
  next()
})

export default router;
