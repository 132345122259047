import {lazyLoad, lazyLoadComponent} from '@/router/coreRouting'

const defaultAdminPath = "admin/"

const metaObj = {
  meta: {
    requiresAuth: true,
    role: "editor"
  }
}

export default [
  {
    path: "/admin-login",
    name: "Admin Login",
    component: lazyLoad("AdminLoginPage", defaultAdminPath),
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: {name: "Admin Login"},
    component: lazyLoadComponent("AdminLayout", defaultAdminPath),
    children: [
      {
        path: "berita",
        name: "Admin Berita",
        component: lazyLoad("AdminBerita", defaultAdminPath),
        ...metaObj
      },
      {
        path: "berita/add",
        name: "Admin Berita Add",
        component: lazyLoad("AdminBeritaAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "berita/edit/:id",
        name: "Admin Berita Edit",
        component: lazyLoad("AdminBeritaEdit", defaultAdminPath),
        ...metaObj
      },

      // NOTE: jika sudah ada componentnya jgn lupa di update
      {
        path: "agenda",
        name: "Admin Agenda",
        component: lazyLoad("AdminAgenda", defaultAdminPath),
        ...metaObj
      },
      {
        path: "agenda/add",
        name: "Admin Agenda Add",
        component: lazyLoad("AdminAgendaAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "agenda/edit/:id",
        name: "Admin Agenda Edit",
        component: lazyLoad("AdminAgendaEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "testimoni",
        name: "Admin Testimoni",
        component: lazyLoad("AdminTestimoni", defaultAdminPath),
        ...metaObj
      },
      {
        path: "testimoni/add",
        name: "Admin Testimoni Add",
        component: lazyLoad("AdminTestimoniAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "testimoni/edit/:id",
        name: "Admin Testimoni Edit",
        component: lazyLoad("AdminTestimoniEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "slide",
        name: "Admin Slide",
        component: lazyLoad("AdminSlide", defaultAdminPath),
        ...metaObj
      },
      {
        path: "slide/add",
        name: "Admin Slide Add",
        component: lazyLoad("AdminSlideAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "slide/edit/:id",
        name: "Admin Slide Edit",
        component: lazyLoad("AdminSlideEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "pidato",
        name: "Admin Pidato",
        component: lazyLoad("AdminPidato", defaultAdminPath),
        ...metaObj
      },
      {
        path: "pidato/add",
        name: "Admin Pidato Add",
        component: lazyLoad("AdminPidatoAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "pidato/edit/:id",
        name: "Admin Pidato Edit",
        component: lazyLoad("AdminPidatoEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "sarana-prasarana",
        name: "Admin Infrastruktur",
        component: lazyLoad("AdminInfrastruktur", defaultAdminPath),
        ...metaObj
      },
      {
        path: "sarana-prasarana/add",
        name: "Admin Infrastruktur Add",
        component: lazyLoad("AdminInfrastrukturAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "sarana-prasarana/edit/:id",
        name: "Admin Infrastruktur Edit",
        component: lazyLoad("AdminInfrastrukturEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "kurikulum-panduan",
        name: "Admin Panduan",
        component: lazyLoad("AdminPanduan", defaultAdminPath),
        ...metaObj
      },
      {
        path: "kurikulum-panduan/add",
        name: "Admin Panduan Add",
        component: lazyLoad("AdminPanduanAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "kurikulum-panduan/edit/:id",
        name: "Admin Panduan Edit",
        component: lazyLoad("AdminPanduanEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "penelitian",
        name: "Admin Penelitian",
        component: lazyLoad("AdminPenelitian", defaultAdminPath),
        ...metaObj
      },
      {
        path: "penelitian/add",
        name: "Admin Penelitian Add",
        component: lazyLoad("AdminPenelitianAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "penelitian/edit/:id",
        name: "Admin Penelitian Edit",
        component: lazyLoad("AdminPenelitianEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "publikasi",
        name: "Admin Publikasi",
        component: lazyLoad("AdminPublikasi", defaultAdminPath),
        ...metaObj
      },
      {
        path: "publikasi/add",
        name: "Admin Publikasi Add",
        component: lazyLoad("AdminPublikasiAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "publikasi/edit/:id",
        name: "Admin Publikasi Edit",
        component: lazyLoad("AdminPublikasiEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "komunitas-mahasiswa",
        name: "Admin Komunitas Mahasiswa",
        component: lazyLoad("AdminKomunitasMahasiswa", defaultAdminPath),
        ...metaObj
      },
      {
        path: "komunitas-mahasiswa/add",
        name: "Admin Komunitas Mahasiswa Add",
        component: lazyLoad("AdminKomunitasMahasiswaAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "komunitas-mahasiswa/edit/:id",
        name: "Admin Komunitas Mahasiswa Edit",
        component: lazyLoad("AdminKomunitasMahasiswaEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "faq",
        name: "Admin FAQ",
        component: lazyLoad("AdminFAQ", defaultAdminPath),
        ...metaObj
      },
      {
        path: "faq/add",
        name: "Admin FAQ Add",
        component: lazyLoad("AdminFAQAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "faq/edit/:id",
        name: "Admin FAQ Edit",
        component: lazyLoad("AdminFAQEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "lowongan-kerja",
        name: "Admin Lowongan Kerja",
        component: lazyLoad("AdminLowonganKerja", defaultAdminPath),
        ...metaObj
      },
      {
        path: "lowongan-kerja/add",
        name: "Admin Lowongan Kerja Add",
        component: lazyLoad("AdminLowonganKerjaAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "lowongan-kerja/edit/:id",
        name: "Admin Lowongan Kerja Edit",
        component: lazyLoad("AdminLowonganKerjaEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "kerjasama",
        name: "Admin Kerjasama",
        component: lazyLoad("AdminKerjasama", defaultAdminPath),
        ...metaObj
      },
      {
        path: "kerjasama/add",
        name: "Admin Kerjasama Add",
        component: lazyLoad("AdminKerjasamaAdd", defaultAdminPath),
        ...metaObj
      },
      {
        path: "kerjasama/edit/:id",
        name: "Admin Kerjasama Edit",
        component: lazyLoad("AdminKerjasamaEdit", defaultAdminPath),
        ...metaObj
      },
      {
        path: "dosen",
        name: "Admin Dosen",
        component: lazyLoad("AdminDosen", defaultAdminPath),
        ...metaObj
      },
      {
        path: "dosen/edit/:id",
        name: "Admin Dosen Edit",
        component: lazyLoad("AdminDosenEdit", defaultAdminPath),
        ...metaObj
      },
    ]
  },
]